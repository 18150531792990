import { routerPaths } from 'config/routerPaths';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './index.styles';

// Social Icons
import FacebookIcon from 'assets/images/facebook-icon.png';
import InstagramIcon from 'assets/images/instagram-icon.png';
import LinkedinIcon from 'assets/images/linkedin.png';
import TikTokIcon from 'assets/images/tiktok-icon.png';
import TwitterIcon from 'assets/images/twitter-icon.png';
import YoutubeIcon from 'assets/images/youtube-icon.png';

//comp
import { Button } from 'components/button';
import Logo from 'components/logo';
//antd
import { Col } from 'antd';
//constants
import { FooterMenuItem } from 'common/constants/footerMenu';
import { useCommon } from '../../context/common/common.context';

// import { socialMediaItems } from "common/constants/socialMedia";

const Footer = () => {
    const {
        aboutUs,
        shariyaCompliance,
        contactUs,
        howItWorks,
        faqs,
        business
        // beAPart
    } = routerPaths;
    const { t, i18n } = useTranslation();
    const data = useCommon();
    const footerMenuItems: FooterMenuItem[] = [
        {
            title: t('footer.menuGroup1Title'),
            links: [
                { label: t('footer.menuGroup1Label1'), href: aboutUs },
                {
                    label: t('footer.menuGroup1Label2'),
                    href: shariyaCompliance
                },
                { label: t('footer.menuGroup1Label3'), href: contactUs },
                { label: t('footer.lowest.menu1'), href: routerPaths.privacy },
                { label: t('footer.lowest.menu2'), href: routerPaths.termsConditions },
                {
                    label: t('footer.lowest.menu3'),
                    href: t('footer.lowest.menu3.urls')
                }
            ]
        },
        {
            title: t('footer.menuGroup2Title'),
            links: [
                { label: t('footer.menuGroup2Label1'), href: howItWorks },
                { label: t('footer.menuGroup2Label2'), href: faqs }
            ]
        },
        {
            title: t('footer.menuGroup3Title'),
            links: [
                // {label: t("footer.menuGroup3Label1"), href: beAPart},
                { label: t('footer.menuGroup3Label1'), href: faqs },
                { label: t('footer.menuGroup3Label2'), href: business },
                { label: t('footer.menuGroup3Label3'), href: 'https://merchant.mispay.co' }
            ]
        }
    ];
    const socialMediaLinks = [
        {
            url: 'https://twitter.com/mispay_sa',
            icon: TwitterIcon,
            alt: 'X'
        },
        {
            url: 'https://www.facebook.com/share/PoXuDg9aNaZTqYMR/?mibextid=LQQJ4d',
            icon: FacebookIcon,
            alt: 'Facebook'
        },
        {
            url: 'https://www.instagram.com/mispay_sa/',
            icon: InstagramIcon,
            alt: 'Instagram'
        },
        {
            url: 'https://www.tiktok.com/@mispaysa',
            icon: TikTokIcon,
            alt: 'TikTok'
        },
        {
            url: 'https://www.linkedin.com/company/mis-pay',
            icon: LinkedinIcon,
            alt: 'LinkedIn'
        },
        {
            url: 'https://www.youtube.com/channel/UC3fEen1sZ7DmO4HdTyYFmHA',
            icon: YoutubeIcon,
            alt: 'Youtube'
        }
    ];

    return (
        <S.FooterStyled>
            <div className="container">
                <S.RowStyledBecome className="become-and-banner">
                    <Col xs={24} md={14} className="become">
                        <S.Title>{data.source.widgets?.footerCard?.title}</S.Title>
                        <S.SubTitle
                            dangerouslySetInnerHTML={{
                                __html: data.source.widgets?.footerCard?.description || ''
                            }}
                        />
                        {data.source.widgets?.footerCard?.link && (
                            <Button type="default">
                                <Link to={data.source.widgets.footerCard.link}>
                                    {t('footer.banner.btnText')}
                                </Link>
                            </Button>
                        )}
                    </Col>
                    <Col xs={24} md={10} className="banner">
                        {data.source.widgets?.footerCard?.image?.url && (
                            <img src={data.source.widgets.footerCard.image?.url} alt="banner" />
                        )}
                    </Col>
                </S.RowStyledBecome>
                <S.RowStyledSocialAndMenu className="social-media-and-menu">
                    <Col xs={24} md={10}>
                        {i18n.language === 'ar' && (
                            <>
                                <br />
                                <br />
                                <br />
                                <br />
                            </>
                        )}
                        <Logo type="light" />
                        <S.Paragraph>{t('footer.text')}</S.Paragraph>
                        <S.SocialMedia>
                            <ul>
                                {socialMediaLinks.map((link, index) => (
                                    <li key={index}>
                                        <a href={link.url} target="_blank" rel="noreferrer">
                                            <img src={link.icon} alt={link.alt} />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </S.SocialMedia>
                    </Col>
                    <Col xs={24} md={14}>
                        {i18n.language === 'ar' && (
                            <>
                                <br />
                                <br />
                                <br />
                                <br />
                            </>
                        )}
                        <S.ItemContainer>
                            {footerMenuItems.map((menuItem, index) => (
                                <div className="item" key={index}>
                                    <h6 className="menu-title">{menuItem.title}</h6>
                                    <ul className="menu">
                                        {menuItem.links.map((link: any, linkIndex: any) => (
                                            <li key={linkIndex}>
                                                <Link to={link.href}>{link.label}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </S.ItemContainer>
                    </Col>
                </S.RowStyledSocialAndMenu>
                <S.Copyright className="copyright">
                    <Col xs={24} md={12}>
                        <span>{t('footer.lowest.text')}</span>
                    </Col>
                    <Col xs={24} md={12}></Col>
                </S.Copyright>
            </div>
        </S.FooterStyled>
    );
};

export default Footer;
