import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import nl2br from 'react-nl2br';
import * as S from './index.styles';

//ui
import { Dropdown, Input, Menu } from 'antd';
//comp
import { SvgIcon } from 'components/svg-icon';

const { Search } = Input;

export interface BannerDynamicProps {
    image: any;
    title: any;
    subTitle: any;
    mobileImage?: any;
    category: boolean;
    items?: any;
    onSearch?: (searchTerm: string) => void;
    onCategoryChange?: (categoryKey: string) => void;
    allCategoryLabel?: string;
}

const BannerDynamic = ({
    image,
    title,
    subTitle,
    mobileImage,
    category,
    items,
    onSearch,
    onCategoryChange,
    allCategoryLabel
}: BannerDynamicProps) => {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        onSearch?.(searchTerm);
    };

    const handleCategoryChange = (selectedKeys: any) => {
        const selectedCategoryKey = selectedKeys.key;
        if (selectedCategoryKey === 'all') {
            onCategoryChange?.('');
            setSelectedCategory(allCategoryLabel || t('shop.search.allCategories'));
        } else {
            const selectedCategoryName = selectedKeys.item.props.children;
            setSelectedCategory(selectedCategoryName);
            onCategoryChange?.(selectedCategoryKey);
        }
    };

    return (
        <S.BannerDynamicStyled
            style={
                {
                    // backgroundImage: `url('${windowWidth <= 767 ? mobileImage : image}')`,
                }
            }
        >
            <div className="container" style={{ height: '100%' }}>
                <S.Info>
                    <S.Title>{nl2br(title)}</S.Title>
                    <S.Subtitle>{nl2br(subTitle)}</S.Subtitle>
                    <S.Search className={`${category ? 'shop-search' : 'faq-search'}`}>
                        <Search
                            prefix={<SvgIcon name="search-icon" />}
                            suffix={
                                <>
                                    {category ? (
                                        <>
                                            <Dropdown
                                                overlay={
                                                    <Menu onClick={handleCategoryChange}>
                                                        <Menu.Item key="all">
                                                            {allCategoryLabel ||
                                                                t('shop.search.allCategories')}
                                                        </Menu.Item>
                                                        {items?.map((item: any) => (
                                                            <Menu.Item key={item.id}>
                                                                {item.en}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu>
                                                }
                                            >
                                                <span>
                                                    {selectedCategory || t('shop.search.text')}
                                                </span>
                                            </Dropdown>
                                            <SvgIcon name="arrow-icon" className="arrow-icon" />
                                        </>
                                    ) : (
                                        <S.Send>
                                            <span>{t('shop.search')}</span>
                                            <SvgIcon
                                                name="send-icon"
                                                className="send-icon"
                                                size={24}
                                            />
                                        </S.Send>
                                    )}
                                </>
                            }
                            placeholder={
                                category
                                    ? t('shop.search.placeholder')
                                    : t('shop.search.findAllThe')
                            }
                            allowClear
                            size="large"
                            onChange={handleSearch}
                        />
                    </S.Search>
                </S.Info>
                <div className="gradient"></div>
            </div>
        </S.BannerDynamicStyled>
    );
};

export default BannerDynamic;
